<script setup>
import { computed } from 'vue'
import { useAuthUserStore } from '@/stores/auth';

const auth = useAuthUserStore();

const props = defineProps({
    is: {
        type: [Array, String],
        default: 'standard-user'
    },
    can: {
        type: [Array, String],
        default: ''
    },
    isNot: {
        type: [Array, String],
        default: ''
    },
    canNot: {
        type: [Array, String],
        default: ''
    }
})

const roles = auth.getUserRoles

function allow(search){

    return roles.some(role => search.includes(role.slug))

}

function deny(search){
    return roles.some(role => search.includes(role.slug))
}

const allowed = computed(() => allow(props.is))

const denied = computed(() => deny(props.isNot))


</script>
<template>
    <template v-if="allowed">
        <slot></slot>
    </template>

    <template v-if="denied">
        <slot></slot>
    </template>
</template>

import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { fetchReports, storeReport, updateReport, archiveReport } from "@/services/ReportService"
import { useNavigationStore } from "@/stores/navigation";
// import { getError } from "@/utils/helpers";

export const useReportStore = defineStore('reports', () => {

    const navigationStore = useNavigationStore();

    const reports = ref([]);
    const activeReport = ref(null);

    const getReports = computed(() => reports.value)
    const getActiveReport = computed(() => activeReport.value)

    function setActiveReport(report){
        activeReport.value = report
    }

    async function setReports(){
        reports.value = await fetchReports();
    }

    async function addReport(payload){
        let report = await storeReport(payload)

        reports.value.push(report)

        let node = createNode(report)
        navigationStore.addNode(node)
    }

    async function editReport(payload){
        updateReport(getActiveReport.value._id, payload)
    }

    async function removeReport(reportId, reportIndex){
        await archiveReport(reportId)

        reports.value.splice(reportIndex, 1)

        navigationStore.removeNode(reportId);
    }

    function createNode(report){
        let subPages = [];

        report.pages.forEach(page => {
            subPages.push({
                id: page._id,
                name: page.title, 
                to: 'report-page',
                params: { report_id: report._id, page_slug: page.slug, page_id: page._id }
            })
        })

        let node = {
            id: report._id,
            name: report.title,
            icon: 'si si-docs',
            subActivePaths: '/reports',
            sub: subPages
        }

        return node
    }

    async function createReportNavigationNodes(){
        let nodeIndex = 1        
        reports.value.forEach(report => {
            let node = createNode(report)
            navigationStore.addNode(node, 'reports-heading', nodeIndex)
            nodeIndex++ // increase the node index to ensure that the elements are always added below the previous node
        })
    }

    function $reset() {
        reports.value = []
        activeReport.value = null
    }

    return {
        getActiveReport,
        setActiveReport,
        getReports,
        setReports,
        addReport,
        editReport,
        removeReport,
        createReportNavigationNodes,
        $reset
    }
});
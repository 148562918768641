import { ref, computed } from "vue";
import { defineStore } from "pinia";

export const useChartTypeStore = defineStore('chartType', () => {
    
    // Set default chart type to 'line'
    const chartType = ref('column')

    const availableChartTypes = [
        {
            title: 'Column',
            value: 'column',
            icon: 'fa fa-chart-column'
        },
        {
            title: 'Bar',
            value: 'bar',
            icon: 'fa fa-chart-bar'
        },
        {
            title: 'Line',
            value: 'line',
            icon: 'fa fa-chart-line'
        },
        {
            title: 'Area',
            value: 'area',
            icon: 'fa fa-chart-area'
        },
        {
            title: 'Pie',
            value: 'pie',
            icon: 'fa fa-chart-pie'
        },
        {
            title: 'Donut',
            value: 'donut',
            icon: 'fa fa-circle-notch'
        },
        {
            title: 'Table',
            value: 'table',
            icon: 'fa fa-table'
        },
        {
            title: 'BAN',
            value: 'ban',
            icon: 'fa fa-xmarks-lines'
        },
        {
            title: 'Scatter',
            value: 'scatter',
            icon: 'si si-graph'
        },
    ]

    const getChartType = computed(() => chartType.value)

    function setChartType(selectedChartType){

        chartType.value = selectedChartType
    }

    function $reset(){
        chartType.value = 'column'
    }

    return {
        chartType,
        getChartType,
        availableChartTypes,
        setChartType,
        $reset
    }
})
<script setup>
import { useAuthUserStore } from '@/stores/auth';

const store = useAuthUserStore();

function logout() {
    store.logout()
}
</script>

<template>
    <button
      type="button"
      @click="logout"
      class="inline-flex items-center space-x-2"
    >
      <slot></slot>
    </button>
  </template>
  
  
import { createApp, defineAsyncComponent } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import axiosPlugin from '@/plugins/axios.js';
import PrimeVue from 'primevue/config';

// Router
import router from "./router";

// Template components
import BaseBlock from "@/components/BaseBlock.vue";
import BaseBackground from "@/components/BaseBackground.vue";
import BasePageHeading from "@/components/BasePageHeading.vue";
import Gate from '@/components/authorization/Gate.vue';

// Template directives
import clickRipple from "@/directives/clickRipple";

// Bootstrap framework
import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
})

// Craft new application
const app = createApp(App);

// Register global components
app.component("BaseBlock", BaseBlock);
app.component("BaseBackground", BaseBackground);
app.component("BasePageHeading", BasePageHeading);
app.component("Gate", Gate);

app.component('TotalLiquidityChart', defineAsyncComponent(() =>
  import('@/cube/TotalLiquidityChart.vue')
))

app.component('InterestCostChart', defineAsyncComponent(() =>
  import('@/cube/InterestCostChart.vue')
))

// Register global directives
app.directive("click-ripple", clickRipple);

// Use Pinia and Vue Router
app.use(createPinia());
app.use(router);

// Add axios to the app and use app-level provide for access
app.use(axiosPlugin, {withCredentials: true});
app.provide('axios', app.config.globalProperties.$axios);

// Add bugsnag to the app and use app-level provide for access
app.use(Bugsnag.getPlugin('vue'))

// Register global variables
app.config.productionTip = false;

app.use(PrimeVue, { unstyled: true });

// ..and finally mount it!
app.mount("#app");

import { ref, computed} from "vue";
import { defineStore } from "pinia";
import { useReportStore } from "@/stores/reports";
import { useDashboardStore } from "@/stores/dashboards";
import { useAuthUserStore } from "@/stores/auth";

export const useNavigationStore = defineStore("navigation", () => {
    
    const auth = useAuthUserStore();
    const reportStore = useReportStore();
    const dashboardStore = useDashboardStore();

    const standardNodes = [
        {
            id: 'overview',
            name: 'Overview',
            to: 'landing',
            icon: 'si si-rocket',
        },
        {
            id: 'dashboards-heading',
            name: 'Dashboards',
            heading: true,
        },
        {
            id: 'reports-heading',
            name: 'Reports',
            heading: true,
        }
    ];

    const analystNodes = [
        {
            id: 'admin',
            name: 'Admin',
            heading: true,
        },
        {
            id: 'users',
            name: 'Users',
            to: 'users',
            icon: 'si si-users',
        },
        {
            id: 'backend-heading',
            name: 'Backend',
            heading: true,
        },
        // {
        //     id: 'backend-page-templates',
        //     name: 'Page Templates',
        //     to: 'page-templates',
        //     icon: 'si si-docs',
        // },
        {
            id: 'backend-reports',
            name: 'Reports',
            to: 'reports',
            icon: 'fa fa-r',
        },
        {
            id: 'backend-dashboards',
            name: 'Dashboards',
            to: 'dashboards',
            icon: 'si si-grid',
        },
        
        {
            id: 'backend-dashboard-items',
            name: 'Dashboard Items',
            to: 'dashboard-items',
            symbolIcon: 'add_chart',
        },
    ]

    const projectManagerNodes = [
        // {
        //     id: 'backend-heading',
        //     name: 'Backend',
        //     heading: true,
        // },
        
    ]

    const adminNodes = [
        {
            id: 'admin',
            name: 'Admin',
            heading: true,
        },
        {
            id: 'users',
            name: 'Users',
            to: 'users',
            icon: 'si si-users',
        },
        {
            id: 'backend-heading',
            name: 'Backend',
            heading: true,
        },
        
        {
            id: 'backend-page-templates',
            name: 'Page Templates',
            to: 'page-templates',
            icon: 'si si-docs',
        },
        {
            id: 'backend-reports',
            name: 'Reports',
            to: 'reports',
            icon: 'fa fa-r',
        },
        {
            id: 'backend-dashboards',
            name: 'Dashboards',
            to: 'dashboards',
            icon: 'si si-grid',
        },
        
        {
            id: 'backend-dashboard-items',
            name: 'Dashboard Items',
            to: 'dashboard-items',
            symbolIcon: 'add_chart',
        },
    ];

    const nodes = ref([]);

    const getNodes = computed(() => nodes.value);

    async function initReportNodes(){
        setNodes();

        await reportStore.setReports()
        await dashboardStore.setDashboards()

        await reportStore.createReportNavigationNodes()
        await dashboardStore.createDashboardNavigationNodes()
    }

    function setNodes(){
        if(auth.getIsAdmin === true){
            nodes.value = standardNodes.concat(adminNodes)
        } else if(auth.getIsAnalyst === true) {
            nodes.value = standardNodes.concat(analystNodes)
        } else if(auth.getIsProjectManager === true){
            nodes.value = standardNodes.concat(projectManagerNodes)
        } else {
            nodes.value = standardNodes
        }
    }

    function addNode(node, insertAfterHeadingId, nodeIndex = 1){
        if(insertAfterHeadingId){
            let headingIndex = nodes.value.findIndex((node) => node.id == insertAfterHeadingId)
            
             nodes.value.splice(headingIndex + nodeIndex, 0, node)
        } else {
            nodes.value.push(node)
        }
        
    }

    function editNode(nodeId, node){
        let index = nodes.value.findIndex((node) => node.id == nodeId)
        
        nodes[index] = node
    }

    function removeNode(id){
        let index = nodes.value.findIndex((node) => node.id == id)

        nodes.value.splice(index, 1)
    }

    function $reset(){
        nodes.value = []
    }
    

  return { nodes, getNodes, addNode, editNode, removeNode, initReportNodes, $reset };
});

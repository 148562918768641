import { ref } from "vue";
import { defineStore } from "pinia";
import { useNavigationStore } from "@/stores/navigation";
import { getDashboards, storeDashboard, updateDashboard, archiveDashboard } from "@/services/DashboardService";

// import { getError } from "@/utils/helpers";

export const useDashboardStore = defineStore('dashboards', () => {

    const navigationStore = useNavigationStore();

    const dashboards = ref([]);
    const activeDashboard = ref(null)

    function setActiveDashboard(dashboard){
        activeDashboard.value = dashboard
    }

    async function setDashboards(){
        dashboards.value = await getDashboards();
    }

    async function addDashboard(payload){
        let dashboard = await storeDashboard(payload)

        dashboards.value.push(dashboard)

        let node = createNode(dashboard)
        navigationStore.addNode(node, 'dashboards-heading')
    }

    async function editDashboard(payload){
        updateDashboard(activeDashboard.value._id, payload)
    }

    async function removeDashboard(dashboardId, dashboardIndex){
        await archiveDashboard(dashboardId)

        dashboards.value.splice(dashboardIndex, 1)

        navigationStore.removeNode(dashboardId)
    }

    function createNode(dashboard){
        let node = {
            id: dashboard._id,
            name: dashboard.title,
            icon: 'si si-grid',
            to: 'dashboard',
            params: { dashboardId: dashboard._id}
        }

        return node
    }

    async function createDashboardNavigationNodes(){
        let nodeIndex = 1;
        dashboards.value.forEach(dashboard => {

            let node = createNode(dashboard)
            navigationStore.addNode(node, 'dashboards-heading', nodeIndex)

            nodeIndex++;

        })
    }

    function $reset() {
        dashboards.value = []
        activeDashboard.value = null
    }

    return {
        dashboards, 
        activeDashboard,
        setActiveDashboard,
        setDashboards,
        addDashboard,
        editDashboard,
        removeDashboard,
        createNode,
        createDashboardNavigationNodes,
        $reset
    }
});
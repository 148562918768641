import { axiosClient } from '@/services/AxiosService';
import { useAuthUserStore } from '@/stores/auth';
import Bugsnag from '@bugsnag/js';

const fetchReports = async () => {
    try {
        const auth = useAuthUserStore();

        const response = await axiosClient.get('/api/v1/'+ auth.getCompanyId +'/reports')

        return response.data
    } catch (error) {
        // Handle error
        Bugsnag.notify(error)
    }
}

const storeReport = async (payload) => {
    try {        
        const response = await axiosClient.post('/api/v1/reports', payload)

        return response.data
    } catch (error) {
        // Handle error
        Bugsnag.notify(error)
    }
}

const updateReport = async (id, payload) => {
    try {        
        const response = await axiosClient.put('/api/v1/reports/' + id + '/update', payload)

        return response.data
    } catch (error) {
        // Handle error
        Bugsnag.notify(error)
    }
}

const archiveReport = async (id) => {
    try {        
        const response = await axiosClient.delete('/api/v1/reports/archive/' + id, null)

        return response.data
    } catch (error) {
        // Handle error
        Bugsnag.notify(error)
    }
}


export { fetchReports, storeReport, updateReport, archiveReport } 
<script setup>
import { ref, onBeforeMount } from "vue";
import { storeToRefs } from "pinia";

import BaseLayout from "@/layouts/BaseLayout.vue";
import BaseNavigation from "@/components/BaseNavigation.vue";
import Logout from "@/layouts/partials/Logout.vue";

import { useTemplateStore } from "@/stores/template";
import { useAuthUserStore } from "@/stores/auth";
import { useNavigationStore } from "@/stores/navigation";

// Main store
const store = useTemplateStore();
const auth = useAuthUserStore();
const navigation = useNavigationStore();

// TODO: Update
const username = ref('User');
const company = ref('Company');

if(auth.getAuthenticatedUser){
  username.value = auth.getAuthenticatedUser.name;
  company.value = auth.getCompanyName;
}


// Set default elements for this layout
store.setLayout({
  header: true,
  sidebar: true,
  sideOverlay: true,
  footer: true,
});

// Set various template options for this layout variation
store.headerStyle({ mode: "light" });
store.mainContent({ mode: "narrow" });

onBeforeMount(() => {
  // initialise navigation fetching
  navigation.initReportNodes()

  navigation.getNodes;
})

</script>

<template>
  <BaseLayout>
    <!-- Side Overlay Content -->
    <!-- Using the available v-slot, we can override the default Side Overlay content from layouts/partials/SideOvelay.vue -->
    <template #side-overlay-content>
      <div class="content-side">
        <p>Side Overlay content..</p>
      </div>
    </template>
    <!-- END Side Overlay Content -->

    <!-- Sidebar Content -->
    <!-- Using the available v-slot, we can override the default Sidebar content from layouts/partials/Sidebar.vue -->
    <template #sidebar-content>
      <div class="content-side">
        <BaseNavigation
          :nodes="navigation.nodes"
        />
      </div>
    </template>
    <!-- END Sidebar Content -->

    <!-- Header Content Left -->
    <!-- Using the available v-slot, we can override the default Header content from layouts/partials/Header.vue -->
    <template #header-content-left>
      <!-- Toggle Sidebar -->
      <button
        type="button"
        class="btn btn-sm btn-alt-secondary me-2 d-lg-none"
        @click="store.sidebar({ mode: 'toggle' })"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>
      <!-- END Toggle Sidebar -->

      <!-- Toggle Mini Sidebar -->
      <button
        type="button"
        class="btn btn-sm btn-alt-secondary me-2 d-none d-lg-inline-block"
        @click="store.sidebarMini({ mode: 'toggle' })"
      >
        <i class="fa fa-fw fa-ellipsis-v"></i>
      </button>
      <!-- END Toggle Mini Sidebar -->
    </template>
    <!-- END Header Content Left -->
    
    <!-- Header Content Right -->
    <!-- Using the available v-slot, we can override the default Header content from layouts/partials/Header.vue -->
    <template #header-content-right>
      <!-- User Dropdown -->
      <div class="dropdown d-inline-block ms-2">
        <button
          type="button"
          class="btn btn-sm btn-alt-secondary d-flex align-items-center"
          id="page-header-user-dropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img
            class="rounded-circle"
            src="/assets/media/avatars/avatar10.jpg"
            alt="Header Avatar"
            style="width: 21px"
          />
          <span class="d-none d-sm-inline-block ms-2">{{ username }}</span>
          <i
            class="fa fa-fw fa-angle-down d-none d-sm-inline-block opacity-50 ms-1 mt-1"
          ></i>
        </button>
        <div
          class="dropdown-menu dropdown-menu-md dropdown-menu-end p-0 border-0"
          aria-labelledby="page-header-user-dropdown"
        >
          <div class="p-3 text-center bg-body-light border-bottom rounded-top" >
            <img
              class="img-avatar img-avatar48 img-avatar-thumb"
              src="/assets/media/avatars/avatar10.jpg"
              alt="Header Avatar"
            />
            <p class="mt-2 mb-0 fw-medium">{{ username }}</p>
            <span class="mt-2 mb-0 fs-sm">{{ company }}</span>
          </div>
          <div class="p-2">
            <RouterLink v-if="!auth.getIsStandardUser" to="/select-company" class="dropdown-item d-flex align-items-center justify-content-between">
              <span class="fs-sm fw-medium">Select Company</span>
            </RouterLink>
            <Logout class="dropdown-item d-flex align-items-center justify-content-between">
              <span class="fs-sm fw-medium">Log Out</span>
            </Logout>
          </div>
        </div>
      </div>
      <!-- END User Dropdown -->
      <!-- Toggle Side Overlay -->
      <!-- <button
        type="button"
        class="btn btn-sm btn-alt-secondary ms-2"
        @click="store.sideOverlay({ mode: 'toggle' })"
      >
        <i class="fa fa-fw fa-list-ul fa-flip-horizontal"></i>
      </button> -->
      <!-- END Toggle Side Overlay -->
    </template>
    <!-- END Header Content Right -->

    <!-- Footer Content Left -->
    <!-- Using the available v-slot, we can override the default Footer content from layouts/partials/Footer.vue -->
    <template #footer-content-left>
      <strong>Insight</strong> by Bankbrokers 
      &copy; {{ store.app.copyright }}
    </template>
    <!-- END Footer Content Left -->
  </BaseLayout>
</template>

import { defineAsyncComponent } from 'vue'

export const getError = (error) => {
    const errorMessage = "API Error, please try again.";
  
    if (error.name === "Fetch User") {
      return error.message;
    }
  
    if (!error.response) {
      console.error(`API ${error.config.url} not found`);
      return errorMessage;
    }
    if (process.env.NODE_ENV === "development") {
      console.error(error.response.data);
      console.error(error.response.status);
      console.error(error.response.headers);
    }
    if (error.response.data && error.response.data.errors) {
      return error.response.data.errors;
    }
  
    return errorMessage;
  };


// Page Components available for page render / builder
const ContentBlock = defineAsyncComponent(() => 
  import('@/components/page/ContentBlock.vue')
)

const OverviewMetrics = defineAsyncComponent(() => 
  import('@/components/page/OverviewMetrics.vue')
)

const TabPanel = defineAsyncComponent(() => 
  import('@/components/page/TabPanel.vue')
)

const DashboardItem = defineAsyncComponent(() => 
  import('@/components/page/DashboardItem.vue')
)

const pageComponents = {
  "OverviewMetrics": OverviewMetrics,
  "TabPanel": TabPanel,
  "ContentBlock": ContentBlock,
  "DashboardItem": DashboardItem
}

export function stringToComponent (component) {
  if(pageComponents[component]){
      return pageComponents[component]
  } else {
      return null
  }
}
import { axiosClient } from '@/services/AxiosService';
import { useAuthUserStore } from '@/stores/auth';
import Bugsnag from '@bugsnag/js';

const getDashboards = async () => {
    
    const auth = useAuthUserStore();

    try {
        const response = await axiosClient.get('/api/v1/'+ auth.getCompanyId +'/dashboards')

        return response.data
    } catch (error) {
        throw error
    }
}

const getDashboard = async (id) => {
    try {
        const response = await axiosClient.get('/api/v1/dashboard/'+ id )

        return response.data
    } catch (error) {
        throw error
    }
}

const storeDashboard = async (payload) => {
    try {        
        const response = await axiosClient.post('/api/v1/dashboard', payload)

        return response.data
    } catch (error) {
        throw error
    }
}

const updateDashboard = async (id, payload) => {
    try {        
        const response = await axiosClient.put('/api/v1/dashboard/'+ id + '/update', payload)

        return response.data
    } catch (error) {
        throw error
    }
}

const archiveDashboard = async (id) => {
    try {        
        const response = await axiosClient.delete('/api/v1/dashboard/archive/'+ id, null)

        return response.data
    } catch (error) {
        throw error
    }
}

export { 
    getDashboards, 
    getDashboard, 
    storeDashboard,
    updateDashboard, 
    archiveDashboard
} 
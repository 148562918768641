import axios from "axios";

// For initialization outside of component: https://pinia.vuejs.org/core-concepts/outside-component-usage.html
import { useAuthUserStore } from "@/stores/auth";

export const authClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: true, // required to handle the CSRF token
});

/*
 * Add a response interceptor
 */
authClient.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const store = useAuthUserStore();
    
    if (
      error.response &&
      [401, 419].includes(error.response.status) &&
      store.getAuthenticatedUser &&
      !store.getGuest
    ) {
      store.logout
    }
    return Promise.reject(error);
  }
);

export default {
  async login(payload) {
    await authClient.get("/sanctum/csrf-cookie");
    return authClient.post("/login", payload);
  },
  logout() {
    return authClient.post("/logout");
  },
  async forgotPassword(payload) {
    await authClient.get("/sanctum/csrf-cookie");
    return authClient.post("/forgot-password", payload);
  },
  async getCubejsToken(payload){
    await authClient.get("/sanctum/csrf-cookie");
    return authClient.post("/api/v1/cubejs/token", payload);
  },
  async getAuthUser() {
    return authClient.get("/api/v1/users/auth");
  },
  async resetPassword(payload) {
    await authClient.get("/sanctum/csrf-cookie");
    return authClient.post("/reset-password", payload);
  },
  updatePassword(payload) {
    return authClient.put("/user/password", payload);
  },
  async registerUser(payload) {
    await authClient.get("/sanctum/csrf-cookie");
    return authClient.post("/register", payload);
  },
  sendVerification(payload) {
    return authClient.post("/email/verification-notification", payload);
  },
//   updateUser(payload) {
//     return authClient.put("/user/profile-information", payload);
//   },
};
import { ref, computed } from "vue";
import { defineStore } from "pinia";

export const useChartSettingsStore = defineStore('chartSettings', () => {

    // Measures, Dimensions and Time Dimensions are collectively referred to as "Members"

    const settings = ref({
        measures: [],
        dimensions: [],
        timeDimensions: [],
        chartFormat: []
    });

    const getSettings = computed(() => settings.value)

    function setInitialSettings(payload){
        settings.value = payload
    }

    function getChartFormatParameter(parameterName, defaultValue = null){
        let parameter = { name: parameterName, value: defaultValue}
        
        let getParam = settings.value.chartFormat.find(p => p.name === parameterName)

        if(getParam){
            parameter = getParam
        }

        return parameter.value
    }

    function setChartFormatParameter(parameterName, parameterValue = null){
        let getParam = settings.value.chartFormat.find(p => p.name === parameterName)

        if(getParam){
            getParam.value = parameterValue
        } else {
            settings.value.chartFormat.push({name: parameterName, value: parameterValue})
        }
    }

    function getMemberParameter(member, memberName, parameterName, defaultValue = null){
        let mem = settings.value[member].find(m => m.name === memberName)

        // add the default array if array does not already exist
        mem.parameters = mem.parameters || []

        let parameter = {name: parameterName, value: defaultValue }

        let getParam = mem.parameters.find(p => p.name === parameterName)
        if(getParam){
            parameter = getParam 
        } 

        return parameter.value
    }

    function setMemberParameter(member, memberName, parameterName, parameterValue = null){
        let mem = settings.value[member].find(m => m.name === memberName)

        // add the default array if array does not already exist
        mem.parameters = mem.parameters || []

        let parameters = mem.parameters.find(p => p.name === parameterName)

        // Update if parameters exists, otherwise push new parameters to to the Member
        if(parameters){
            parameters.value = parameterValue
        } else {
            mem.parameters.push({name: parameterName, value: parameterValue})
        }
    }

    function getMemberFormatType(member, memberName){
        let mem = settings.value[member].find(m => m.name === memberName)

        // Add the default Format object if it does not exist
        if(mem){
            mem.format = mem.format || { type: "", options: [] }

            return mem.format.type
        }
        
    }

    function setMemberFormatType(member, memberName, formatValue){
        let mem = settings.value[member].find(x => x.name === memberName)
        
        // Add the default Format object if it does not exist
        mem.format = mem.format || { type: "", options: [] }

        mem.format.type = formatValue

        return mem.format.type
    }

    

    function getMemberFormatOption(member, memberName, optionName, defaultValue = null){
        let mem = settings.value[member].find(m => m.name === memberName)

        mem.format.options = mem.format?.options || []

        let option = {name: optionName, value: defaultValue }

        let getOption = mem.format.options.find(o => o.name === optionName)
        if(getOption){
            option = getOption
        } 

        return option.value
    }

    function setMemberFormatOption(member, memberName, optionName, optionValue = null){
        let mem = settings.value[member].find(m => m.name === memberName)
        
        // Add the default Format object if it does not exist
        mem.format = mem.format || { type: "", options: [] }

        let option = mem.format.options.find(o => o.name === optionName)

        if(option){
            option.value = optionValue
        } else {
            mem.format.options.push({name: optionName, value: optionValue})
        }

    }

    // Functions for getting or setting measures, dimensions or timeDimensions members
    function getMember(member, memberName){
        let mem = settings.value[member].find(m => m.name === memberName )

        return mem
    }

    function setMembers(member, memberName){
        if(!getMember(member, memberName)){
            let defaultSettings = { 
                name: memberName, 
                format: { 
                    type: "", options: [] 
                }, 
                parameters: []
            }

            if(member === 'measures' || member === 'dimensions'){
                defaultSettings.parameters.push(
                    { 
                        name: 'stacked', 
                        value: false 
                    }, 
                    { 
                        name: 'stackName', 
                        value: 'Total'
                    },
                    {
                        name: 'label',
                        value: true
                    }
                )
            } else {
                defaultSettings.parameters.push(
                    {
                        name: 'label',
                        value: true
                    }
                )
            }

            settings.value[member].push(defaultSettings)
        }
    }

    function removeMembers(member, memberName){
        let memberIndex = settings.value[member].findIndex(m => m.name === memberName)
        settings.value[member].splice(memberIndex, 1)
    }

    function $reset() {
        settings.value = {
            measures: [],
            dimensions: [],
            timeDimensions: [],
            chartFormat: []
        }
    }

    return {
        settings,
        getSettings,
        setInitialSettings,
        getChartFormatParameter,
        setChartFormatParameter,
        setMemberParameter,
        getMemberParameter,
        setMemberFormatType,
        getMemberFormatType,
        setMemberFormatOption,
        getMemberFormatOption,
        getMember,
        setMembers,
        removeMembers,
        $reset
    }
});
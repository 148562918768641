import { ref, computed } from "vue";
import { defineStore } from "pinia";
import AuthService from "@/services/AuthService";
import { getError } from "@/utils/helpers";
import router from "@/router";
import { useReportStore } from "@/stores/reports";
import { useDashboardStore } from "@/stores/dashboards";
import { useNavigationStore } from "@/stores/navigation";
import Bugsnag from "@bugsnag/js";

export const useAuthUserStore = defineStore('auth-user', () => {

    // Stores
    const navigationStore = useNavigationStore();
    const reportStore = useReportStore();
    const dashboardStore = useDashboardStore();

    // State
    const user = ref(null);
    const loading = ref(false);
    const error = ref(null);
    const cubeToken = ref(null);
    const companyId = ref(null);
    const companyName = ref(null);

    // Getters
    const getAuthenticatedUser = computed(() => user.value );
    const getIsAdmin = computed(() => user.value ? user.value.isAdmin : false );
    const getIsAnalyst= computed(() => user.value ? user.value.isAnalyst: false );
    const getIsProjectManager = computed(() => user.value ? user.value.isProjectManager : false );
    const getIsStandardUser = computed(() => user.value ? user.value.isStandardUser : true );
    const getUserRoles = computed(() => user.value ? user.value.roles : [] );
    const getCubejsToken = computed(() => cubeToken.value ? cubeToken.value : null );
    const getCompanyId = computed(() => companyId.value ? companyId.value : null );
    const getCompanyName = computed(() => companyName.value ? companyName.value : null );
    const getError = computed(() => error);
    const getLoading = computed(() => loading);
    const getLoggedIn = computed(() => !!user.value);
    const getGuest = computed(() => storageItem());

    // Actions
    function storageItem(){
        const storageItem = window.localStorage.getItem("guest");
        
        if (!storageItem) 
            return false;
        if (storageItem === "isGuest") 
            return true;
        if (storageItem === "isNotGuest") 
            return false;
    }

    function logout() {
        return AuthService.logout()
          .then(() => {            
            // Reset all relevant stores
            $reset()
            reportStore.$reset()
            dashboardStore.$reset()
            navigationStore.$reset()
            
            setGuest("isGuest");
            if (router.currentRoute.name !== "login")
              router.push({ path: "/login" });
          })
          .catch((error) => {
            error.value = getError(error);
          });
    }

    async function fetchAuthUser() {
        loading.value = true;
        try {
          const response = await AuthService.getAuthUser();
            user.value = response.data;
            setCompanyId(response.data.companies[0].id)
            setCompanyName(response.data.companies[0].name)
            loading.value = false;
            setGuest('isNotGuest');
            Bugsnag.setUser(user.value.id, user.value.email, user.value.name)

            return response.data;
        } catch (error) {
          loading.value = false;
          user.value = null;
          error.value = error;
        }
      }

      async function fetchCubejsToken(payload){
        try {
          const response = await AuthService.getCubejsToken(payload);
            cubeToken.value = response.data;
            return response.data;
        } catch (error){
          error.value = error;
        }
      }
      
      function setGuest(value) {
        window.localStorage.setItem("guest", value);
      }

      function setCompanyId(id) {
        companyId.value = id
      }

      function setCompanyName(name) {
        companyName.value = name
      }

      function $reset(){
        user.value = null;
        cubeToken.value = null;
        companyId.value = null;
        error.value = null;
        loading.value = false;
      }

      return {
        getAuthenticatedUser,
        getIsAdmin,
        getIsAnalyst,
        getIsProjectManager,
        getIsStandardUser,
        getUserRoles,
        getCubejsToken,
        getCompanyId,
        getError,
        getLoading,
        getLoggedIn,
        getGuest,
        getCompanyName,
        fetchAuthUser,
        fetchCubejsToken,
        setCompanyId,
        setCompanyName,
        logout,
        $reset
      }
    
})

import axios from 'axios'

export default {
    install: (app, options) => {
        app.config.globalProperties.$axios = axios.create({
            baseURL: options.baseUrl ? options.baseUrl : import.meta.env.VITE_APP_URL,
            withCredentials: options.withCredentials ? options.withCredentials : false,
            headers: {
                Authorization: options.token ? `Bearer ${options.token}` : '',
            }
        })
    }
}
import { createRouter, createWebHashHistory } from "vue-router";

import NProgress from "nprogress/nprogress.js";


// Main layouts

// Frontend (Plain style)
import LayoutSimple from "@/layouts/variations/Simple.vue";

// Backend (Dashboard / admin style)
import LayoutBackend from "@/layouts/BackendBaseLayout.vue";

// Store(s)
import { useAuthUserStore } from "@/stores/auth";
import { useChartTypeStore } from "@/stores/chartType";
import { useChartSettingsStore } from "@/stores/chartSettings";


// Frontend: Login
const Login = () => import("@/views/auth/Login.vue");
const SelectCompany = () => import("@/views/auth/SelectCompany.vue");
const Reminder = () => import("@/views/auth/Reminder.vue");

// PAGES
const LandingPage = () => import("@/views/LandingPage.vue");
const BasePageRenderer = () => import("@/views/reports/BasePageRenderer.vue");

// DASHBOARDS

const BaseDashboardRenderer = () => import("@/views/dashboards/BaseDashboardRenderer.vue");

// Backend Admin
const Users = () => import("@/views/backend/admin/Users.vue");

// Backend: Dashboard
const Reports = () => import("@/views/backend/Reports.vue");
const Dashboards = () => import("@/views/backend/Dashboards.vue");
const PageTemplates = () => import("@/views/backend/PageTemplates.vue");
const DashboardItems = () => import("@/views/backend/DashboardItems.vue");
const ChartBuilder = () => import("@/views/backend/builder/ChartBuilder.vue");
const PageBuilder = () => import("@/views/backend/builder/PageBuilder.vue");


// Set all routes
const routes = [
  {
    path: "/",
    component: LayoutBackend,
    children: [
      {
        path: '',
        component: LandingPage,
        name: 'landing',
        meta: { requiresAuth: true },
      }
    ]
    
  },

  {
    path: "/login",
    name: "login",
    component: Login,
    children: [
      {
        path: "reminder",
        name: "password-reminder",
        component: Reminder,
      },
    ],
  },

  {
    path: "/select-company",
    name: "select-company",
    meta: { requiresAuth: true },
    component: SelectCompany,
  },

  {
    path: "/backend",
    component: LayoutBackend,
    meta: { requiresAuth: true },
    children: [

      {
        path: "admin/users",
        name: "users",
        component: Users,
        meta: { requiresAuth: true }
      },

      {
        path: "reports",
        name: "reports",
        component: Reports,
        meta: { requiresAuth: true }
      },

      {
        path: "dashboards",
        name: "dashboards",
        component: Dashboards,
        meta: { requiresAuth: true }
      },

      {
        path: "page-templates",
        name: "page-templates",
        component: PageTemplates,
        meta: { requiresAuth: true }
      },

      {
        path: "dashboard-items",
        name: "dashboard-items",
        component: DashboardItems,
        meta: { requiresAuth: true }
      },

      {
        path: "chart-builder/:dashboardItemId?",
        name: "chart-builder",
        component: ChartBuilder,
        meta: { requiresAuth: true }
      },

      {
        path: "page-builder/:page_id",
        name: "page-builder",
        component: PageBuilder,
        props: { isPageTemplate: false },
        meta: { requiresAuth: true }
      },

      {
        path: "page-builder/:page_id",
        name: "page-template-builder",
        component: PageBuilder,
        props: { isPageTemplate: true },
        meta: { requiresAuth: true }
      },

      {
        path: "page-builder/:page_id",
        name: "dashboard-builder",
        component: PageBuilder,
        props: { isPageTemplate: false, isDashboard: true },
        meta: { requiresAuth: true }
      },
    ],
  },

  {
    path: "/dashboards",
    component: LayoutBackend,
    meta: { requiresAuth: true },
    children: [
      {
        path: "dashboard/:dashboardId",
        name: "dashboard",
        component: BaseDashboardRenderer,
        meta: { requiresAuth: true }
      }
    ],
  },

  {
    path: "/reports/:report_id",
    component: LayoutBackend,
    children: [
      {
        path: "page/:page_slug/:page_id",
        name: "report-page",
        component: BasePageRenderer,
        meta: { requiresAuth: true }
      },

      // {
      //   path: "page/bank-accounts",
      //   name: "page/bank-accounts",
      //   component: BankAccountsPage,
      //   meta: { requiresAuth: true }
      // },

      // {
      //   path: "foreign-exchange",
      //   name: "foreign-exchange",
      //   component: BasePageRenderer,
      //   meta: { requiresAuth: true }
      // },

      // {
      //   path: "other-products",
      //   name: "other-products",
      //   component: BasePageRenderer,
      //   meta: { requiresAuth: true }
      // },
    ],
  },
];

// Create Router
const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
  routes,
});

// NProgress
/*eslint-disable no-unused-vars*/
NProgress.configure({ showSpinner: false });

router.beforeEach((to, from) => {
  const authStore = useAuthUserStore();

  const authenticatedUser = authStore.getAuthenticatedUser;
  const cubejsToken = authStore.getCubejsToken;
  const requiresAuthentication = to.matched.some((record) => record.meta.requiresAuth);
  const loginQuery = { path: "/login", query: { redirect: to.fullPath } };

  // Check if the user is authenticated. 
  // Redirect to login if not, continue to requested page if authenticated
  if (requiresAuthentication && !authenticatedUser) {
    authStore.fetchAuthUser().then(() => {
      if (!authStore.getAuthenticatedUser){
          return loginQuery;
      }

      if(!cubejsToken && authStore.getIsStandardUser === true){
         let payload = {
          user_id: authStore.getAuthenticatedUser.id,
          company_id: authStore.getCompanyId
         }

         authStore.fetchCubejsToken(payload).then(() =>
            router.push(to)
         )
         .catch((error) => 
          Bugsnag.notify(error)
        );


      }

      router.push(to);
    })

    return loginQuery
  }

  // Check if cubejsToken is set and avoid infinite redirect
  if(!cubejsToken && to.name !== 'select-company'){
    if(authStore.getIsStandardUser === false){
        return { name: 'select-company' }
    }
  }

});

router.beforeResolve((to, from, next) => {
  NProgress.start();
  next();
});

router.afterEach((to, from) => {

  // reset states if navigating away from the Chart builder
  if(from.name === 'chart-builder'){
    const chartTypeStore = useChartTypeStore();
    const chartSettingsStore = useChartSettingsStore();

    // Reset chart settings
    chartSettingsStore.$reset()

    // Reset chart type selection
    chartTypeStore.$reset()
  }
  
  NProgress.done();
});
/*eslint-enable no-unused-vars*/

export default router;
